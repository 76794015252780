
















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import AuthModule from '@/store/modules/auth'
import LessonForm from '@/components/forms/lesson/LessonForm.vue'
import CourseProgramMonthMixin from '@/mixins/manager/CourseProgramMonthMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { LessonStore } from '@/store/types'
import { convertDateToMSK } from '@/utils/functions'

@Component({
  components: { LessonForm },
})
export default class CoursesItemProgramMonthLessonsNew extends Mixins(CourseProgramMonthMixin) {
  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handleSubmit (payload: LessonStore) {
    if (this.month) {
      ManagerCoursesModule.saveLesson({
        courseID: this.month.course.id,
        monthID: this.month.id,
        params: {
          ...payload,
          startPublishedAt: payload.startPublishedAt ? convertDateToMSK(payload.startPublishedAt, this.isLocalTimezone) : payload.startPublishedAt,
          spendingAt: convertDateToMSK(payload.spendingAt, this.isLocalTimezone),
        },
      })
        .then(() => {
          this.notifySuccess('Урок создан')
          if (this.month) {
            this.$router.push({
              name: 'manager.control.courses.item.program.month.lessons',
              params: {
                courseID: this.month.course.id.toString(),
                monthID: this.month.id.toString(),
              },
            })
          }
        })
        .catch(this.notifyError)
    }
  }

  private destroyed() {
    this.$bus.$off('add-exercise-from-template', this.fetchMonth as any)
  }

  private metaInfo (): MetaInfo {
    return {
      title: `Создание урока. ${this.monthName}. ${this.month ? this.courseTitle : ''}`,
    }
  }
}
